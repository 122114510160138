<template>
  <b-row class="justify-content-center">
    <b-col cols="10">
      <b-card
        no-body
        class="card-developer-meetup"
      >
        <div class="bg-light-primary rounded-top text-center">
          <b-img
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            alt="Meeting Pic"
            height="170"
            class="mt-3 mb-3"
          />
        </div>
        <b-card-body>
          <!-- metting header -->
          <div class="meetup-header d-flex justify-content-center">
            <div class="my-auto">
              <h2 class="mb-25 text-center">
                {{ $t('idy.contactPage.contact') }}
              </h2>
            </div>
          </div>
          <!--/ metting header -->

          <b-row class="justify-content-center mb-3">
            <b-col cols="4">
              <!-- media -->
              <b-media
                v-for="media in mediaData"
                :key="media.id"
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      :icon="media.avatar"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ media.title }}
                  </h6>
                  <small>{{ media.subtitle }}</small>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BImg,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  VBTooltip,
  BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BImg,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      mediaData: [
        {
          id: 1,
          avatar: 'MapPinIcon',
          title: this.$t('idy.contactPage.address'),
          subtitle: 'Казахстан, Алматы, Улица Сатпаева 90а',
        },
        {
          id: 2,
          avatar: 'PhoneIcon',
          title: this.$t('idy.contactPage.techSupport'),
          subtitle: '+(775) 127 9898',
        },
        {
          id: 3,
          avatar: 'PhoneIcon',
          title: this.$t('idy.contactPage.marketing'),
          subtitle: '+(775) 127 9898',
        },
        {
          id: 4,
          avatar: 'MailIcon',
          title: this.$t('idy.contactPage.email'),
          subtitle: 'info@simpra.kz',
        },
      ],
    }
  },
}
</script>
